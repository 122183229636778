<template>
    <div
        :class="{
            'account-name-cell': true,
            'account-name-cell-disabled': isUnlinkableAccount,
        }"
        @click="handleSelectAccount()"
    >
        <div v-if="updatingLinkedAccounts && accountIsChecked" class="spinner-container">
            <Spinner />
        </div>
        <Checkbox :model-value="accountIsChecked" v-else :disabled="isUnlinkableAccount" />

        <Spacer width="1.25rem" />
        <div class="linked-accounts-row-name">
            <Avatar
                :color="accountColor"
                :width="36"
                show-account-type
                :platform="account.platform"
            >
                {{ account.initials }}
            </Avatar>
            <Spacer width="1.125rem" />
            <span class="linked-accounts-row-domain-name">
                {{ account.name }}
            </span>
        </div>

        <!-- Recently Added/Personal LN Acc Icon -->
        <svg
            v-if="isUnlinkableAccount || recentlyAddedAccount"
            v-tippy="{
                animateFill: 'false',
                animation: 'shift-away',
                duration: '250',
                placement: 'top',
                theme: 'circle-icon',
                distance: 8,
                content: `<span class='f7 lh-copy'>${isUnlinkableAccount ? 'Personal accounts are not supported on Opteo.' : 'Recently added'}</span>`,
            }"
            class="recently-added-icon"
            width="20"
            height="20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 20.0001c5.5228 0 10-4.4771 10-10C20 4.47727 15.5228.00012207 10 .00012207 4.47715.00012207 0 4.47727 0 10.0001c0 5.5229 4.47715 10 10 10ZM10 5c.5523 0 1 .44772 1 1v4c0 .5523-.4477 1-1 1-.55229 0-1-.4477-1-1V6c0-.55228.44771-1 1-1Zm1.5 8.5c0 .8284-.6716 1.5-1.5 1.5-.82843 0-1.5-.6716-1.5-1.5S9.17157 12 10 12c.8284 0 1.5.6716 1.5 1.5Z"
                fill="#E0E0E5"
            />
        </svg>
    </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount } from 'vue'
import { Spacer, Avatar, Checkbox, Spinner } from '@opteo/components-next'
import { Account, Team } from '@opteo/types'
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'
import isBefore from 'date-fns/isBefore'

const props = defineProps<{ account: Team.Account; linked: boolean }>()

const {
    selectAccount,
    queuedLinkedAccounts,
    queuedUnlinkedAccounts,
    updatingLinkedAccounts,
    unlinkedVisitedUnmountTS,
} = useLinkedAccounts()

const accountIsChecked = computed(() => {
    if (!props.account || isUnlinkableAccount.value === true) return false
    const accountId = props.account.accountId as Account.ID

    if (props.linked) return queuedLinkedAccounts.value[accountId] >= 0

    return queuedUnlinkedAccounts.value[accountId] >= 0
})

const accountColor = computed(() => {
    if (!props.account) return ''
    if (props.linked) return props.account.color
    return '#CACAD3'
})

const recentlyAddedAccount = computed(
    () =>
        !props.linked &&
        !!isBefore(unlinkedVisitedUnmountTS.value, new Date(props.account.connectedToOpteoTs))
)

// Right now, the only way an account is unlinkable is if it's a Personal LI account.
// For that reason, it is valid to use a specific tooltip saying "Personal accounts aren't supported in Opteo"
const isUnlinkableAccount = computed(() => !props.linked && props.account.isUnlinkable)

const handleSelectAccount = () => {
    if (isUnlinkableAccount.value !== true) {
        selectAccount(props.account, props.linked, !accountIsChecked.value)
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.account-name-cell-disabled {
    cursor: default;
}
.account-name-cell-disabled:active {
    pointer-events: none;
}
.linked-accounts-row-domain-name {
    @include f-7;
    @include fw-500;
    @include no-wrap;
    color: v-bind(accountColor);
    line-height: 4.625rem;
    height: 4.625rem;
    @include no-select;
    @include flex;
    @include items-center;
}
.linked-accounts-row-name::after {
    content: '';
    height: 100%;
    @include absolute;
    top: 0;
    right: 0;
    background: white;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    width: 4rem;
}
.recently-added-icon {
    @include ml-8;
    outline: none;
}

@media screen and (max-width: $mq-767-max) {
    .linked-accounts-row-domain-name {
        line-height: unset;
        height: unset;
    }
    .linked-accounts-row-name::after {
        height: unset;
        top: unset;
        right: unset;
        background: unset;
        width: unset;
    }
}
</style>
