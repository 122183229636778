import { computed, ref, provide, inject, watch, onMounted } from 'vue'
import { useLocalStorage, useSessionStorage, StorageSerializers } from '@vueuse/core'
import { useRouter } from 'vue-router'
import { Account, Targets } from '@opteo/types'

import differenceInDays from 'date-fns/differenceInDays'
import subDays from 'date-fns/subDays'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import isSameWeek from 'date-fns/isSameWeek'
import isSameMonth from 'date-fns/isSameMonth'

import orderBy from 'lodash-es/orderBy'
import groupBy from 'lodash-es/groupBy'
import sortBy from 'lodash-es/sortBy'
import find from 'lodash-es/find'

import {
    SS_PERFORMANCE_DATE_RANGE,
    LS_PERFORMANCE_DATE_DIFFERENCE,
    LS_GRAPHS_SIDEBAR,
} from '@/lib/cookies'

import { Routes } from '@/router/routes'

import { Endpoint } from '@/composition/api/endpoints'
import { useAPI } from '@/composition/api/useAPI'
import { ProvideKeys } from '@/composition/useProvide'
import { charts } from './chartConstants'

import {
    TimePeriod,
    Layout,
    AccountData,
    SidebarPresets,
    SidebarCampaign,
    DateRange,
    ChartID,
} from './types'
import { useCampaignGroups } from '../campaignGroups/useCampaignGroups'
import { useAccount } from '../account/useAccount'
import { Platform } from '@opteo/types/platform/platform'

export function providePerformanceControls() {
    const { accountId, domainId, accountPlatform } = useAccount()

    // DATE RANGE
    const sessionDateRange = useSessionStorage<DateRange>(
        SS_PERFORMANCE_DATE_RANGE(accountId.value),
        null,
        { serializer: StorageSerializers.object }
    )

    const localDateDifference = useLocalStorage<number>(
        LS_PERFORMANCE_DATE_DIFFERENCE(accountId.value),
        null,
        { serializer: StorageSerializers.number }
    )

    if (!localDateDifference.value) {
        localDateDifference.value = 89
    }

    const startDate = ref(new Date())
    const endDate = ref(new Date())

    if (sessionDateRange.value) {
        // In the same session, the selected date range should be preserved.
        startDate.value = new Date(sessionDateRange.value.start)
        endDate.value = new Date(sessionDateRange.value.end)
    } else {
        // When not in the same session, only the difference between the selected dates should be preserved.
        startDate.value.setDate(startDate.value.getDate() - localDateDifference.value - 1)
        endDate.value.setDate(endDate.value.getDate() - 1)
    }

    const dateDifference = computed(() => differenceInDays(endDate.value, startDate.value))
    const startOfStartDate = computed(() => startOfDay(startDate.value))
    const endOfEndDate = computed(() => endOfDay(endDate.value))
    const extendedStartDate = computed(() =>
        startOfDay(subDays(startDate.value, dateDifference.value + 1))
    )

    function applyDateRange(args: DateRange) {
        startDate.value = args.start
        endDate.value = args.end
        sessionDateRange.value = args
        localDateDifference.value = dateDifference.value
    }

    provide(ProvideKeys.PerformanceStartDate, startOfStartDate)
    provide(ProvideKeys.PerformanceEndDate, endOfEndDate)
    provide(ProvideKeys.PerformanceExtendedStartDate, extendedStartDate)

    // SIDE BAR CONTROL

    const sidebarOpen = ref(false)
    function toggleSidebarOpen() {
        sidebarOpen.value = !sidebarOpen.value
    }

    // SIDEBAR TITLE

    const { currentRoute } = useRouter()

    const routeName = computed(() => currentRoute.value.name)

    const sidebarPresets = useLocalStorage<SidebarPresets>(
        LS_GRAPHS_SIDEBAR(accountId.value),
        null,
        { serializer: StorageSerializers.object }
    )

    if (!sidebarPresets.value || !sidebarPresets.value?.selectedConversionActions) {
        // selectedConversionActions did not exist in a previous release so we need to recover from invalid localStorage data
        sidebarPresets.value = {
            selectedViewOption: TimePeriod.WEEK,
            selectedLayoutOption: Layout.GRID,
            deselectedCampaignIds: [],
            selectedConversionActions: [],
            selectedCampaignGroups: [],
            deselectedCharts: [],
            isDefaults: true,
        }
    }

    if (!extendedStartDate || !startDate || !endDate || !dateDifference) {
        throw new Error('Date range had not been provided.')
    }

    // SIDEBAR CONTROL
    if (!sidebarOpen) {
        throw new Error('Sidebar state has not been provided.')
    }

    // VIEW OPTION

    const timePeriods = computed(() => {
        return [
            {
                value: TimePeriod.DAY,
                label: `Daily <span style='opacity: 0.3; margin-left: 0.25rem'>Find small day-to-day anomalies.</span>`,
                disabled: Math.abs(differenceInDays(endDate.value, startDate.value)) > 365,
            },
            {
                value: TimePeriod.WEEK,
                label: `Weekly <span style='opacity: 0.3; margin-left: 0.25rem'>Analyse trends as they evolve.</span>`,
                disabled:
                    isSameWeek(startDate.value, endDate.value, { weekStartsOn: 1 }) ||
                    dateDifference.value < 9,
            },
            {
                value: TimePeriod.MONTH,
                label: `Monthly <span style='opacity: 0.3; margin-left: 0.25rem'>Track long term performance.</span>`,
                disabled:
                    isSameMonth(startDate.value, endDate.value) ||
                    isSameWeek(startDate.value, endDate.value, { weekStartsOn: 1 }) ||
                    dateDifference.value < 9,
            },
        ]
    })

    const selectedTimePeriod = ref<TimePeriod>(
        sidebarPresets.value.selectedViewOption ?? TimePeriod.DAY
    )

    watch(selectedTimePeriod, () => {
        sidebarPresets.value.selectedViewOption = selectedTimePeriod.value
    })

    watch(timePeriods, () => {
        /*
           The order of these if-statements is important.
        */
        if (
            selectedTimePeriod.value === TimePeriod.MONTH &&
            timePeriods.value.find(t => t.value === TimePeriod.MONTH)!.disabled
        ) {
            selectedTimePeriod.value = TimePeriod.WEEK
        }

        if (
            selectedTimePeriod.value === TimePeriod.WEEK &&
            timePeriods.value.find(t => t.value === TimePeriod.WEEK)!.disabled
        ) {
            selectedTimePeriod.value = TimePeriod.DAY
        }

        if (
            selectedTimePeriod.value === TimePeriod.DAY &&
            timePeriods.value.find(t => t.value === TimePeriod.DAY)!.disabled
        ) {
            selectedTimePeriod.value = TimePeriod.WEEK
        }
    })

    // LAYOUT OPTION

    const layoutOptions = [
        {
            value: Layout.GRID,
            label: `Grid <span style='opacity: 0.3; margin-left: 0.25rem'>Graphs arranged in a grid layout.</span>`,
        },
        {
            value: Layout.ROWS,
            label: `Rows <span style='opacity: 0.3; margin-left: 0.25rem'>Graphs arranged in single rows.</span>`,
        },
    ]

    const selectedLayoutOption = ref<Layout>(sidebarPresets.value.selectedLayoutOption)

    watch(selectedLayoutOption, () => {
        sidebarPresets.value.selectedLayoutOption = selectedLayoutOption.value
    })

    // CAMPAIGNS
    const {
        data: rawAccountData,
        loading: accountDataLoading,
        isValidating: accountDataValidating,
        mutate: mutateAccountData,
    } = useAPI<AccountData>(Endpoint.GetPerformanceAccountData, {
        body: () => ({
            raw_from_date: extendedStartDate.value,
            raw_to_date: endDate.value,
        }),
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value,
    })

    const { data: accountConversionActions } = useAPI<Targets.AccountConversionAction[]>(
        Endpoint.GetAccountConversionActions
    )

    watch([rawAccountData, accountConversionActions], () => {
        if (sidebarPresets.value.isDefaults && accountConversionActions.value) {
            sidebarPresets.value.selectedConversionActions = accountConversionActions.value
                .filter(ca => {
                    if (accountPlatform.value === Platform.MetaAds) {
                        return (
                            ca.enabled &&
                            [
                                'purchase',
                                'complete_registration',
                                'start_trial',
                                'lead',
                                'submit_application',
                            ].includes(ca.conversionActionId)
                        )
                    }
                    return ca.enabled
                })
                .map(ca => ca.conversionActionId.toString())

            sidebarPresets.value.isDefaults = false
        }
    })

    function entireChannelSelected(channelName: string) {
        return (
            channels.value
                .find(c => c.channel === channelName)
                ?.campaigns.every(campaign => campaign.selected) ?? false
        )
    }

    const channels = computed<SidebarCampaign[]>(() => {
        if (!rawAccountData.value) {
            return []
        }

        const excludedCamapignIds =
            rawCampaignGroups.value
                ?.find(group => group.campaignGroupType === Targets.CampaignGroupType.EXCLUDED)
                ?.campaigns.map(campaign => campaign.campaignId) ?? []

        return Object.values(
            groupBy(rawAccountData.value.campaigns, c => c.advertising_channel)
        ).map(campaigns => {
            return {
                channel: campaigns[0].advertising_channel,
                campaigns: sortBy(
                    campaigns
                        .filter(campaign => !excludedCamapignIds.includes(campaign.campaign_id))
                        .map(campaign => {
                            return {
                                name: campaign.campaign_name,
                                id: campaign.campaign_id,
                                selected: !sidebarPresets.value.deselectedCampaignIds.includes(
                                    campaign.campaign_id
                                ),
                            }
                        }),
                    campaign => campaign.name
                ),
            }
        })
    })

    const selectedCampaignIds = computed(() => {
        return channels.value.flatMap(channel => {
            return channel.campaigns
                .filter(campaign => campaign.selected)
                .map(campaign => campaign.id)
        })
    })

    const selectedCampaignCount = computed(() => {
        const allCampaigns = channels.value.flatMap(channel => channel.campaigns)
        const selectedCampaigns = allCampaigns.filter(campaign => campaign.selected)

        return `${selectedCampaigns.length}/${allCampaigns.length}`
    })

    function toggleChannel(channelName: string, checked?: boolean) {
        if (typeof checked === 'undefined') {
            checked = !entireChannelSelected(channelName)
        }

        channels.value.forEach(channel => {
            if (channel?.channel && channel.channel === channelName) {
                channel.campaigns.forEach(campaign => {
                    campaign.selected = checked ?? false

                    if (campaign.selected) {
                        sidebarPresets.value.deselectedCampaignIds =
                            sidebarPresets.value.deselectedCampaignIds.filter(
                                campaignId => campaignId !== campaign.id
                            )
                    } else {
                        sidebarPresets.value.deselectedCampaignIds.push(campaign.id)
                    }
                })
            }
        })
    }

    function toggleCampaign(campaignId: number) {
        setCampaignCheckedStatus(campaignId)
    }

    function setCampaignCheckedStatus(campaignId: number, checked?: boolean) {
        channels.value.forEach(channel => {
            channel.campaigns.forEach(campaign => {
                if (campaign.id === campaignId) {
                    campaign.selected = checked ?? !campaign.selected

                    if (campaign.selected) {
                        sidebarPresets.value.deselectedCampaignIds =
                            sidebarPresets.value.deselectedCampaignIds.filter(
                                id => id !== campaign.id
                            )
                    } else {
                        sidebarPresets.value.deselectedCampaignIds.push(campaign.id)
                    }
                }
            })
        })
    }

    // CONVERSION ACTIONS
    const conversionActions = computed(() => {
        if (!rawAccountData.value) {
            return []
        }

        return orderBy(
            rawAccountData.value.conversions.map(conversionAction => {
                const selected = sidebarPresets.value.selectedConversionActions.includes(
                    conversionAction.id
                )

                return { label: conversionAction.name, selected, id: conversionAction.id }
            }),
            'label'
        )
    })

    const selectedConversionActions = computed(() =>
        conversionActions.value.filter(conversionAction => conversionAction.selected)
    )

    const selectedConversionActionCount = computed(() => {
        const allConversionActions = conversionActions.value.map(
            conversionAction => conversionAction.selected
        )
        const selectedConversionActions = allConversionActions.filter(
            conversionAction => conversionAction
        )

        return `${selectedConversionActions.length}/${allConversionActions.length}`
    })

    function toggleConversionAction(index: number) {
        setConversionActionStatus(index, !conversionActions.value[index].selected)
    }

    function setConversionActionStatus(index: number, checked: boolean) {
        conversionActions.value[index].selected = checked
        const { id } = conversionActions.value[index]

        if (checked) {
            sidebarPresets.value.selectedConversionActions.push(id)
        } else {
            sidebarPresets.value.selectedConversionActions =
                sidebarPresets.value.selectedConversionActions.filter(
                    conversionAction => conversionAction !== id
                )
        }
    }

    const isLinkedIn = accountPlatform.value === Platform.LinkedInAds

    // SEGMENTS BITS
    const lookbackWindowSelectValue = ref({
        value: isLinkedIn ? '180' : '30',
        label: isLinkedIn ? 'Last 180 days' : 'Last 30 days',
    }) // it's silly that the Select component expects the whole object instead of just the value.

    const lookbackWindow = computed(() => +lookbackWindowSelectValue.value.value)

    const { campaignGroups: rawCampaignGroups, campaignGroupsLoading } = useCampaignGroups()

    const groupsWithCampaigns = computed(() =>
        rawCampaignGroups.value?.filter(
            group =>
                group.campaigns.length &&
                group.campaignGroupType !== Targets.CampaignGroupType.EXCLUDED
        )
    )

    const selectedGroupId = useLocalStorage<Record<Account.ID, string>>(
        'selectedGroupForSegments',
        {}
    )

    const groupWithMostSpend = computed(
        () => groupsWithCampaigns.value?.sort((a, b) => b.cost - a.cost)?.[0]
    )

    const selectedGroup = computed(() =>
        groupsWithCampaigns.value?.find(
            group => group.id === selectedGroupId.value[accountId.value ?? 'NO_ACCOUNT_ID']
        )
    )

    const campaignGroupItems = computed(() =>
        groupsWithCampaigns.value?.map(group => {
            return {
                value: group.id,
                label: group.name,
            }
        })
    )

    const setInitialSelectedGroupId = () => {
        if (!groupsWithCampaigns.value || !accountId.value) {
            return
        }

        /*
            The selected campaign group lives in local storage.
            On initial load, if the localstorage is empty, select the first non-brand group.
            On initial load, if the localstorage has a group that no longer exists, select the first non-brand group.
        */
        const groupNameAlreadyExists = !!selectedGroupId.value[accountId.value]
        if (groupNameAlreadyExists) {
            const groupNameIsValid = !!groupsWithCampaigns.value.find(
                group => group.id === selectedGroupId.value[accountId.value ?? 0]
            )

            if (groupNameIsValid) {
                return
            }
        }

        const firstNonBrandingGroup = find<Targets.CampaignGroup>(
            groupsWithCampaigns.value,
            group => {
                return group.campaignGroupType !== Targets.CampaignGroupType.BRAND
            }
        )

        if (firstNonBrandingGroup?.name && firstNonBrandingGroup?.cost) {
            selectedGroupId.value[accountId.value] = firstNonBrandingGroup?.id
        } else {
            const hasValidGroup = groupsWithCampaigns.value[0] !== undefined
            if (hasValidGroup) {
                selectedGroupId.value[accountId.value] =
                    groupWithMostSpend.value?.id ?? groupsWithCampaigns.value[0].id
            }
        }
    }

    watch(groupsWithCampaigns, () => setInitialSelectedGroupId())

    onMounted(() => {
        if (groupsWithCampaigns.value?.length) {
            // only if groupsWithCampaigns still has a value
            setInitialSelectedGroupId()
        }
    })

    // CHARTS SELECTION

    const chartsEnabledStatuses = computed(() =>
        charts
            .filter(chart => chart.platforms.includes(accountPlatform.value))
            .map(chart => {
                const relevantChart = sidebarPresets.value.deselectedCharts.find(
                    c => c === chart.id
                )

                return {
                    ...chart,
                    enabled: !relevantChart,
                }
            })
    )

    function toggleChart(id: ChartID) {
        const foundChart = chartsEnabledStatuses.value.find(chart => chart.id === id)
        if (!foundChart) {
            throw new Error('bad chart ID')
        }

        if (foundChart.enabled) {
            sidebarPresets.value.deselectedCharts.push(id)
        } else {
            sidebarPresets.value.deselectedCharts = sidebarPresets.value.deselectedCharts.filter(
                _id => _id !== id
            )
        }
    }

    const selectedChartCount = computed(() => {
        const enabledCharts = chartsEnabledStatuses.value.filter(chart => chart.enabled)
        const totalChartsForPlatform = chartsEnabledStatuses.value.filter(chart =>
            chart.platforms.includes(accountPlatform.value)
        )

        return `${enabledCharts.length}/${totalChartsForPlatform.length}`
    })

    const toProvide = {
        // DATE RANGE
        startDate,
        endDate,
        applyDateRange,
        // SIDEBAR CONTROL
        toggleSidebarOpen,
        sidebarOpen,
        sidebarPresets,
        // NAVIGATION
        Routes,
        routeName,
        // DATE RANGE
        extendedStartDate,
        // VIEW OPTION
        selectedTimePeriod,
        timePeriods,
        // LAYOUT OPTION
        layoutOptions,
        selectedLayoutOption,
        // CAMPAIGNS
        accountDataLoading,
        mutateAccountData,
        accountDataValidating,
        channels,
        selectedCampaignIds,
        selectedCampaignCount,
        entireChannelSelected,
        toggleChannel,
        toggleCampaign,

        // CONVERSION ACTIONS
        conversionActions,
        selectedConversionActions,
        selectedConversionActionCount,
        toggleConversionAction,

        // SEGMENTS
        accountId,
        domainId,
        campaignGroupsLoading,
        groupsWithCampaigns,
        selectedGroup,
        campaignGroupItems,
        selectedGroupId,
        lookbackWindow,
        lookbackWindowSelectValue,

        // CHARTS TOGGLING
        charts,
        chartsEnabledStatuses,
        selectedChartCount,
        toggleChart,
    }

    provide('performance', toProvide)

    return toProvide
}

export function usePerformanceControls() {
    const injected = inject<ReturnType<typeof providePerformanceControls>>('performance')

    if (!injected) {
        throw new Error(
            `PerformanceControls not yet injected, something is wrong. usePerformanceControls() can only be called in a a child of the /performance/ route.`
        )
    }

    return injected
}
