<template>
    <MobileSplashContainer wide>
        RSA Writer is available on screens <b>1200px or wider</b>, please increase your window size
        or use another device. If you'd like to see us build this feature for touch devices, please
        consider submitting a feature request.
    </MobileSplashContainer>
    <FullScreenOverlay moreButton @close="handleRsaWriterClose" @more="panelOpen = !panelOpen">
        <transition name="page-fade" mode="out-in" appear>
            <div v-if="selectorLoading" class="loader">
                <div class="loader-container">
                    <RsaLoaderAnimation />
                    <div class="progress-bar-container">
                        <ProgressBar :progress="progressStep" />
                    </div>
                </div>
            </div>
            <div v-else>
                <!-- Open Campaign -->
                <section
                    v-if="currentState === 'open-campaign' && campaignRows?.length"
                    class="open-campaign"
                    key="open-campaign"
                >
                    <ToolkitContainer title="Open Campaign">
                        <template v-slot:header>
                            <Label :left="true">Sort By</Label>
                            <oInput
                                :items="sortRowsByItems"
                                v-model="sortRowsBy"
                                type="select"
                                class="sort-groups-by"
                                name="sort-campaigns-by"
                            />
                        </template>

                        <GroupRow
                            v-for="item in campaignRows"
                            :group="item"
                            :key="item.name"
                            :currencyCode="domainCurrency"
                            :performance-mode="performanceMode"
                            @click.once="openCampaign(item.id)"
                        />
                    </ToolkitContainer>
                </section>
                <!-- Open Ad Group -->
                <section
                    v-else-if="currentState === 'open-ad-group'"
                    class="open-ad-group"
                    key="open-ad-group"
                >
                    <ToolkitContainer title="Open Ad Group">
                        <template v-slot:header>
                            <Label :left="true">Sort By</Label>
                            <oInput
                                :items="sortRowsByItems"
                                v-model="sortRowsBy"
                                type="select"
                                class="sort-groups-by"
                                name="sort-ad-groups-by"
                            />
                        </template>
                        <GroupRow
                            v-for="item in adGroupRows"
                            :group="item"
                            :currencyCode="domainCurrency"
                            :key="item.name"
                            :performance-mode="performanceMode"
                            @click.once="openAdGroup(item.id)"
                        />
                    </ToolkitContainer>
                </section>
                <!-- Open RSA -->
                <section v-else-if="currentState === 'open-rsa'" class="open-rsa" key="open-rsa">
                    <ToolkitContainer title="Open RSA">
                        <template v-slot:header>
                            <Tooltip
                                v-if="maxRsasAlreadyExist"
                                :maxWidth="325"
                                content="Google Ads has a limit of 3 enabled RSAs per ad group. To create an RSA, pause another RSA in this ad group."
                            >
                                <oButton disabled>
                                    Create New RSA
                                    <template v-slot:icon>
                                        <RightArrowIcon hex="#fff" />
                                    </template>
                                </oButton>
                            </Tooltip>
                            <oButton
                                v-else
                                @clicked="launchWriterNew()"
                                :disabled="maxRsasAlreadyExist"
                            >
                                Create New RSA
                                <template v-slot:icon>
                                    <RightArrowIcon hex="#fff" />
                                </template>
                            </oButton>
                        </template>
                        <div class="open-rsa-container">
                            <!-- Empty State for no RSAs in AdGroup -->
                            <div
                                v-if="!draftRows?.length && !rsaRows?.length"
                                class="no-rsas-empty-state"
                            >
                                <div class="content">
                                    <img
                                        src="@/assets/img/rsa-empty-state.png"
                                        style="width: 11.375rem"
                                    />
                                    <Spacer height="1.5rem" />
                                    <div class="center" style="max-width: 19.5rem">
                                        <Text as="h5" size="f-5" weight="600" align="center">
                                            No RSAs in Ad Group
                                        </Text>
                                        <Spacer height="1rem" />
                                        <Text as="p" size="f-8" align="center">
                                            Opteo could not find any RSAs in this ad group. To
                                            create your first RSA, click the button above.
                                        </Text>
                                    </div>
                                </div>
                            </div>
                            <!-- Draft RSAs -->
                            <Rsa
                                v-for="draft in draftRows"
                                :key="draft.id"
                                :isDraft="true"
                                :rsa="draft"
                                :performance-mode="performanceMode"
                                :currency-code="domainCurrency"
                                title="Draft RSA"
                            >
                                <template v-slot:rsa-draft-action>
                                    <!-- Delete Draft -->
                                    <DraftRowDeleteButton
                                        @delete-confirmed="deleteDraft(draft.id)"
                                    />
                                </template>
                                <template v-slot:rsa-controls-right>
                                    <!-- Open RSA -->
                                    <oButton color="white" @clicked="launchWriterNew(draft.id)">
                                        Open Draft
                                        <template v-slot:icon>
                                            <RightArrowIcon hex="#006dff" />
                                        </template>
                                    </oButton>
                                </template>
                            </Rsa>
                            <!-- Current RSAs -->
                            <Rsa
                                v-for="rsa in rsaRows"
                                :rsa="rsa"
                                :key="rsa.resourceName"
                                :title="'RSA'"
                                :isCurrentDraft="rsa.hasDraft"
                                :performance-mode="performanceMode"
                                :currency-code="domainCurrency"
                            >
                                <template v-slot:rsa-controls-right>
                                    <!-- Open RSA -->
                                    <oButton
                                        :disabled="rsa.status === RsaStatus.PAUSED"
                                        color="white"
                                        @clicked="launchWriterEdit(rsa.id)"
                                    >
                                        Open RSA
                                        <template v-slot:icon>
                                            <RightArrowIcon hex="#006dff" />
                                        </template>
                                    </oButton>
                                </template>
                                <template v-slot:rsa-draft-action>
                                    <DraftRowDeleteButton
                                        v-if="rsa.hasDraft"
                                        :is-existing-rsa="true"
                                        @delete-confirmed="deleteDraft(rsa.draftId)"
                                    />
                                </template>
                                <!-- Status -->
                                <template v-slot:rsa-status>
                                    <Tooltip
                                        v-if="
                                            maxRsasAlreadyExist && rsa.status === RsaStatus.PAUSED
                                        "
                                        :maxWidth="325"
                                        content="Google Ads has a limit of 3 enabled RSAs per ad group. To enable this RSA, pause another RSA in this ad group."
                                    >
                                        <div class="draft-mock-input">
                                            Paused
                                            <SelectArrowIcon />
                                        </div>
                                    </Tooltip>
                                    <Tooltip v-else>
                                        <oInput
                                            :items="[
                                                { value: RsaStatus.ENABLED, label: 'Enabled' },
                                                { value: RsaStatus.PAUSED, label: 'Paused' },
                                            ]"
                                            v-model="rsa.status"
                                            @updated="updateAdStatus($event, rsa, 'rsa')"
                                            type="select"
                                            class="rsa-status"
                                            name="update-rsa-status"
                                            :disabled="
                                                maxRsasAlreadyExist &&
                                                rsa.status === RsaStatus.PAUSED
                                                    ? true
                                                    : undefined
                                            "
                                        />
                                    </Tooltip>
                                </template>
                                <!-- Ad Strength -->
                                <template v-slot:rsa-ad-strength>
                                    <RsaAdStrength :value="rsa.adStrength" />
                                </template>
                            </Rsa>
                            <!-- Current ETAs -->
                            <Rsa
                                v-for="eta in etaRows"
                                :rsa="eta"
                                :key="eta.resourceName"
                                :title="'ETA'"
                                :isEta="true"
                                :show-rsa-permutation-controls="false"
                                :performance-mode="performanceMode"
                                :currency-code="domainCurrency"
                            >
                                <template v-slot:rsa-controls-right>
                                    <!-- Create draft from ETA -->
                                    <oButton color="white" @clicked="convertEtaToDraft(eta)">
                                        Create as Draft
                                        <template v-slot:icon>
                                            <RightArrowIcon hex="#006dff" />
                                        </template>
                                    </oButton>
                                </template>
                                <!-- Status -->
                                <template v-slot:rsa-status>
                                    <oInput
                                        :items="[
                                            { value: RsaStatus.ENABLED, label: 'Enabled' },
                                            { value: RsaStatus.PAUSED, label: 'Paused' },
                                        ]"
                                        v-model="eta.status"
                                        @updated="updateAdStatus($event, eta, 'eta')"
                                        type="select"
                                        class="rsa-status"
                                        name="update-rsa-status"
                                    />
                                </template>
                            </Rsa>
                        </div>
                    </ToolkitContainer>
                </section>
                <section v-else-if="rsaDoesNotExist" key="rsa-not-exists">
                    <div class="no-campaigns-empty-state">
                        <div class="content">
                            <img src="@/assets/img/no-results-small.png" style="height: 10rem" />
                            <Spacer height="1.625rem" />
                            <div class="center" style="max-width: 22.5rem">
                                <Text as="h5" size="f-5" weight="600" align="center">
                                    RSA not found
                                </Text>
                                <Spacer height="1rem" />
                                <Text as="p" size="f-8" align="center">
                                    Opteo could not find this RSA in your account. It may have been
                                    paused or deleted. If you think this is an error on our part,
                                    don't hesitate to contact our support team.
                                </Text>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- RSA Writer -->
                <section
                    v-else-if="currentState === 'write-rsa' || currentState === 'edit-rsa'"
                    class="rsa-writer"
                    key="rsa-writer"
                >
                    <!-- RSA Sidebar -->
                    <div class="rsa-writer-sidebar">
                        <RsaSidebar
                            v-if="workingRsa"
                            v-model:rsa="workingRsa"
                            :original-rsa="originalRsa"
                            :form-is-empty="formIsEmpty"
                            @rsa-updated="rsaUpdated"
                            @fields-changed="fieldsChanged"
                            ref="sidebarRef"
                        />
                    </div>

                    <!-- RSA Writer Main Column -->
                    <div class="rsa-writer-main-column">
                        <!-- RSA Preview -->
                        <GoogleSerpContainer
                            v-if="workingRsa"
                            :rsa="workingRsa"
                            :saving="saving"
                            :search-query="topImpressionSearchTerm"
                        >
                            <template v-slot:rsa-ad-strength>
                                <RsaAdStrength :value="workingRsa.adStrength" />
                            </template>
                        </GoogleSerpContainer>
                        <Spacer height="3rem" />
                        <div class="rsa-writer-inspiration">
                            <!-- Copy Onboarding -->
                            <div v-if="showCopyOnboarding" class="copy-onboarding">
                                <div class="left">
                                    <HelpIcon class="help-icon" />
                                    <Text as="p" size="f-8">
                                        You can copy headlines and descriptions to your clipboard by
                                        <span class="hidden-string">hovering and</span> clicking on
                                        them.
                                    </Text>
                                </div>
                                <oButton color="white" @clicked="dismissTour()"> Dismiss </oButton>
                            </div>
                            <!-- Current RSAs -->
                            <Rsa
                                v-for="rsa in showAllCurrentRsas
                                    ? existingRsasInAdgroup
                                    : existingRsasInAdgroup?.slice(0, 3)"
                                :rsa="rsa"
                                :key="rsa.resourceName"
                                :title="'Current RSA'"
                                :assetsCopyable="true"
                                :performance-mode="performanceMode"
                                :currency-code="domainCurrency"
                            >
                                <template v-slot:rsa-controls-right>
                                    <!-- Copy RSA -->
                                    <oButton iconBefore color="white" @clicked="copyRsaToForm(rsa)">
                                        <template v-slot:icon>
                                            <rsaCopyIcon hex="#006dff" />
                                        </template>
                                        Copy RSA
                                    </oButton>
                                </template>
                                <template v-slot:rsa-ad-strength>
                                    <RsaAdStrength :value="rsa.adStrength" />
                                </template>
                            </Rsa>
                            <!-- Show More RSAs -->
                            <div
                                v-if="existingRsasInAdgroup?.length > 3"
                                style="text-align: center"
                            >
                                <oButton
                                    color="white"
                                    @clicked="showAllCurrentRsas = !showAllCurrentRsas"
                                >
                                    {{ showAllCurrentRsas ? 'Show Less' : 'Show More' }}
                                </oButton>
                            </div>
                            <!-- Competitor Ads -->
                            <RsaCompetitorAds :ads="competitorAds" />
                            <!-- AI Generated Assets -->
                            <RsaAssetCollection
                                :loading="aiAssetsLoading"
                                :error="aiAssetsError"
                                :headlineAssets="aiHeadlineAssets"
                                :descriptionAssets="aiDescriptionAssets"
                                title="AI Generated Assets"
                                entity="Generated"
                            >
                                <template v-slot:header>
                                    <Text as="span" size="f-9">Powered By</Text>
                                    <Spacer width="0.375rem" />
                                    <WritesonicLogo />
                                </template>
                                <template v-slot:empty-state>
                                    <div class="no-search-terms-empty-state">
                                        <div class="content">
                                            <img
                                                src="@/assets/img/scorecard-empty-state.png"
                                                style="height: 10.875rem"
                                            />
                                            <Spacer height="1.625rem" />
                                            <div class="center" style="max-width: 20rem">
                                                <Text
                                                    as="h5"
                                                    size="f-5"
                                                    weight="600"
                                                    align="center"
                                                >
                                                    No Assets Available
                                                </Text>
                                                <Spacer height="1rem" />
                                                <Text as="p" size="f-8" align="center">
                                                    Unfortunately, Writesonic could not generate any
                                                    assets for this RSA. This is usually because the
                                                    ad group uses a language not supported by
                                                    Writesonic.
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-slot:popout="{ score }">
                                    <div v-if="score" class="ai-generated-assets-popout">
                                        <ScorecardDonut
                                            :score="score"
                                            :compact="true"
                                            :width="42"
                                            :strokeWidth="2"
                                        />
                                        <Spacer width="1.25rem" />
                                        <Text as="p" size="f-8">
                                            Asset scores are generated based on three factors:
                                            readability, keyword inclusion and length. The higher
                                            the score, more likely an asset is to be suitable for
                                            your ad.
                                        </Text>
                                    </div>
                                    <div v-else class="ai-generated-assets-popout">
                                        <ScorecardDonut
                                            :hidden="true"
                                            :score="0"
                                            :compact="true"
                                            :width="42"
                                            :strokeWidth="2"
                                        />
                                        <Spacer width="1.25rem" />
                                        <Text as="p" size="f-8">
                                            No asset score could be generated. This is usually
                                            because the language used in the parent ad group is not
                                            supported.
                                        </Text>
                                    </div>
                                </template>
                            </RsaAssetCollection>
                            <!-- Recommended Search Terms -->
                            <RsaRecommendedSearchTerms
                                v-if="recommendedSearchTermsRows"
                                :headers="[
                                    { key: 'text', text: 'Search Term', sortable: true },
                                    {
                                        key: 'impressions',
                                        text: 'Impr.',
                                        width: below1280 ? 104 : 114,
                                        sortable: true,
                                    },
                                    {
                                        key: 'ctr',
                                        text: 'CTR',
                                        width: below1280 ? 108 : 118,
                                        sortable: true,
                                    },
                                    isUsingCpa
                                        ? {
                                              key: 'conversions',
                                              text: 'Conv.',
                                              width: below1280 ? 98 : 108,
                                              sortable: true,
                                          }
                                        : {
                                              key: 'conversionsValue',
                                              text: 'Value',
                                              width: below1280 ? 98 : 108,
                                              sortable: true,
                                          },
                                    {
                                        key: 'includedInRsa',
                                        text: 'Status',
                                        width: below1280 ? 154 : 160,
                                        sortable: true,
                                    },
                                ]"
                                :items="recommendedSearchTermsRows.slice(0, 25)"
                                :currency-code="domainCurrency"
                            >
                                <template v-slot:empty-state>
                                    <div class="no-search-terms-empty-state">
                                        <div class="content">
                                            <img
                                                src="@/assets/img/no-results-small.png"
                                                style="height: 10rem"
                                            />
                                            <Spacer height="1.625rem" />
                                            <div class="center" style="max-width: 22.25rem">
                                                <Text
                                                    as="h5"
                                                    size="f-5"
                                                    weight="600"
                                                    align="center"
                                                >
                                                    No Search Terms Found
                                                </Text>
                                                <Spacer height="1rem" />
                                                <Text as="p" size="f-8" align="center">
                                                    Opteo could not load any recommended search
                                                    terms for this ad group. If you think this is an
                                                    error on our part, please don't hesitate to
                                                    message our support team.
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </RsaRecommendedSearchTerms>
                            <!-- Campaign Library -->
                            <RsaAssetCollection
                                :headlineAssets="campaignHeadlineAssets"
                                :descriptionAssets="campaignDescriptionAssets"
                                title="Campaign Library"
                                entity="Campaign"
                            >
                                <template v-slot:empty-state>
                                    <div class="no-search-terms-empty-state">
                                        <div class="content">
                                            <img
                                                src="@/assets/img/scorecard-empty-state.png"
                                                style="height: 10.875rem"
                                            />
                                            <Spacer height="1.625rem" />
                                            <div class="center" style="max-width: 20rem">
                                                <Text
                                                    as="h5"
                                                    size="f-5"
                                                    weight="600"
                                                    align="center"
                                                >
                                                    No Assets Available
                                                </Text>
                                                <Spacer height="1rem" />
                                                <Text as="p" size="f-8" align="center">
                                                    There are no active RSAs in this campaign, so
                                                    Opteo has no campaign assets to show here.
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-slot:popout="{ score }">
                                    <div v-if="score != 0" class="campaign-library-popout">
                                        <ScorecardDonut
                                            :score="score"
                                            :compact="true"
                                            :width="42"
                                            :strokeWidth="2"
                                        />
                                        <Spacer width="1.25rem" />
                                        <Text as="p" size="f-8">
                                            Opteo takes the
                                            <Text as="span" weight="600">Performance</Text>
                                            rating of this asset across each of the RSAs it appears
                                            in and impression-weights that rating to assign a
                                            performance score. These scores are then averaged to
                                            generate an overall asset performance score.
                                        </Text>
                                    </div>
                                    <div v-else class="campaign-library-popout">
                                        <ScorecardDonut
                                            :hidden="true"
                                            :score="0"
                                            :compact="true"
                                            :width="42"
                                            :strokeWidth="2"
                                        />
                                        <Spacer width="1.25rem" />
                                        <Text as="p" size="f-8">
                                            Opteo takes the
                                            <Text as="span" weight="600">Performance</Text>
                                            rating of this asset across each of the RSAs it appears
                                            in and impression-weights that rating to assign a
                                            performance score. These scores are then averaged to
                                            generate an overall asset performance score.
                                        </Text>
                                    </div>
                                </template>
                            </RsaAssetCollection>
                            <!-- SERP Preview -->
                            <RsaSerpPreview
                                :serpAvailable="serpAvailable"
                                :imageUrl="buildSerpImageUrl()"
                            />
                        </div>
                    </div>
                </section>
                <!-- RSA Preview -->
                <section
                    v-else-if="workingRsa && currentState === 'preview-rsa'"
                    class="preview-rsa"
                    key="preview-rsa"
                >
                    <!-- RSA Preview -->
                    <GoogleSerpContainer
                        :rsa="workingRsa"
                        :saving="saving"
                        :search-query="topSearchTerm"
                    />
                    <Spacer height="3rem" />
                    <!-- Full Preview -->
                    <ToolkitContainer title="Expanded Preview">
                        <RsaExpandedPreview :rsa="workingRsa" />
                    </ToolkitContainer>
                </section>

                <!-- Empty State -->
                <section v-else>
                    <div class="no-campaigns-empty-state">
                        <div class="content">
                            <img src="@/assets/img/no-results-small.png" style="height: 10rem" />
                            <Spacer height="1.625rem" />
                            <div class="center" style="max-width: 22.5rem">
                                <Text as="h5" size="f-5" weight="600" align="center">
                                    No Active Campaigns
                                </Text>
                                <Spacer height="1rem" />
                                <Text as="p" size="f-8" align="center">
                                    Opteo could not find any active search campaigns in your
                                    account. If you think this is an error on our part, please don't
                                    hesitate to contact our support team.
                                </Text>
                            </div>
                        </div>
                    </div>
                </section>

                <!-- RSA Statistics -->
                <Panel :modelValue="panelOpen" @close="panelOpen = false" title="RSA Statistics">
                    <template #content>
                        <div v-if="accountHeadlineAssets.length > 0" class="rsa-statistics-panel">
                            <RsaAssetCollection
                                :headlineAssets="accountHeadlineAssets"
                                :descriptionAssets="accountDescriptionAssets"
                                :noWrapper="true"
                                entity="Top Performing"
                            >
                                <template v-slot:popout="{ score }">
                                    <div class="campaign-library-popout">
                                        <ScorecardDonut
                                            :score="score"
                                            :compact="true"
                                            :width="42"
                                            :strokeWidth="2"
                                        />
                                        <Spacer width="1.25rem" />
                                        <Text as="p" size="f-8">
                                            Opteo takes the
                                            <Text as="span" weight="600">Performance</Text> rating
                                            of this asset across each of the RSAs it appears in and
                                            impression-weights that rating to assign a performance
                                            score. These scores are then averaged to generate an
                                            overall asset performance score.
                                        </Text>
                                    </div>
                                </template>
                            </RsaAssetCollection>
                            <RsaStatistics
                                v-for="block in rsaStatistics"
                                :key="block.key"
                                :donutChart="block.donutChart"
                                :table="block.table"
                                :currencyCode="domainCurrency"
                            />
                        </div>
                        <div v-else class="no-statistics-empty-state">
                            <div class="content">
                                <img
                                    src="@/assets/img/no-results-small.png"
                                    style="height: 10rem"
                                />
                                <Spacer height="1.625rem" />
                                <div class="center" style="max-width: 21.5rem">
                                    <Text as="h5" size="f-5" weight="600" align="center">
                                        No Active RSAs
                                    </Text>
                                    <Spacer height="1rem" />
                                    <Text as="p" size="f-8" align="center">
                                        Opteo could not find any active RSAs in your account. If you
                                        think this is an error on our part, please don't hesitate to
                                        send our support team a message.
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </template>
                </Panel>
            </div>
        </transition>
        <transition name="page-fade" appear>
            <!-- RSA Footer -->
            <RsaFooter>
                <!-- Domain -->
                <Skeleton :width="36" :height="36">
                    <transition name="fade">
                        <Avatar
                            v-if="domainColour && domainInitials && domainName"
                            :color="domainColour"
                            show-account-type
                        >
                            {{ domainInitials }}
                        </Avatar>
                    </transition>
                </Skeleton>
                <Spacer width="1rem" />
                <Tooltip
                    v-if="domainColour && domainInitials && domainName"
                    :content="domainName.length > 20 ? domainName : ''"
                    :offset="[-12, 10]"
                    placement="top-start"
                >
                    <Text
                        as="p"
                        size="f-7"
                        weight="500"
                        :style="{ color: domainColour }"
                        style="letter-spacing: -0.02rem; max-width: 16.25rem; cursor: default"
                        truncate
                    >
                        {{ domainName }}
                    </Text>
                </Tooltip>
                <Skeleton v-else :width="96" :height="12" />
                <Spacer width="1.25rem" />
                <!-- Location -->
                <template v-slot:location>
                    <!-- Campaign -->
                    <transition name="fade">
                        <RsaButton
                            v-if="currentState !== 'open-campaign' && campaignName"
                            @click.prevent="handleBreadcrumb('account')"
                            :value="campaignName"
                        >
                            <CircleCampaignIcon :tooltip="false" />
                        </RsaButton>
                    </transition>
                    <transition name="fade">
                        <Spacer width="0.75rem" />
                    </transition>
                    <!-- Ad Group -->
                    <transition name="fade">
                        <RsaButton
                            v-if="
                                currentState !== 'open-campaign' &&
                                currentState != 'open-ad-group' &&
                                adGroupName
                            "
                            @click.prevent="handleBreadcrumb('campaign')"
                            :value="adGroupName"
                        >
                            <CircleAdgroupIcon :tooltip="false" />
                        </RsaButton>
                    </transition>
                    <transition name="fade">
                        <Spacer width="0.75rem" />
                    </transition>
                    <!-- Keyword -->
                    <Popout
                        v-model="showKeywordPopout"
                        :externalToggleButton="true"
                        :offset="[0, 12]"
                        :zIndex="9999999999999"
                        placement="top"
                        :popperOptions="{
                            strategy: 'fixed',
                        }"
                        :borderRadius="20"
                        maxWidth="50rem"
                    >
                        <transition name="fade">
                            <RsaButton
                                v-if="
                                    currentState != 'open-campaign' &&
                                    currentState != 'open-ad-group' &&
                                    campaignName &&
                                    adGroupName
                                "
                                @click="showKeywordPopout = !showKeywordPopout"
                            >
                                <CircleKeywordIcon :tooltip="false" />
                            </RsaButton>
                        </transition>
                        <template v-slot:content>
                            <div class="keywords-popout">
                                <Text as="h3" size="f-5" weight="600">Top Keywords</Text>
                                <Spacer height="1.75rem" />
                                <div
                                    v-if="keywordsItems?.length === 0"
                                    class="no-keywords-empty-state"
                                >
                                    <div class="content">
                                        <img
                                            src="@/assets/img/no-results-small.png"
                                            style="height: 10rem"
                                        />
                                        <Spacer height="1.625rem" />
                                        <div class="center" style="max-width: 22.5rem">
                                            <Text as="h5" size="f-5" weight="600" align="center">
                                                No Keywords Found
                                            </Text>
                                            <Spacer height="1rem" />
                                            <Text as="p" size="f-8" align="center">
                                                Opteo could not find any active keywords in this ad
                                                group. If you think this is an error on our part,
                                                please don't hesitate to send our support team a
                                                message.
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                                <oTable
                                    v-else
                                    :headers="keywordsHeaders"
                                    :items="keywordsItems?.slice(0, 20)"
                                    :perPage="4"
                                    :fixedLayout="true"
                                    :borderRadius="16"
                                    order-by="impressions"
                                    order="DESC"
                                >
                                    <!-- Impressions -->
                                    <template v-slot:column.keyword="keyword">
                                        <div class="keywords-popout-cell">
                                            <CircleKeywordIcon :tooltip="false" />
                                            <Spacer width="0.5rem" />
                                            {{ keyword.value }}
                                        </div>
                                    </template>
                                    <!-- Impressions -->
                                    <template v-slot:column.impressions="impressions">
                                        <Number :value="impressions.value" />
                                    </template>
                                    <!-- Clicks -->
                                    <template v-slot:column.clicks="clicks">
                                        <Number :value="clicks.value" />
                                    </template>
                                    <!-- CTR -->
                                    <template v-slot:column.ctr="ctr">
                                        <Percent :value="ctr.value" />
                                    </template>
                                    <!-- Conversions -->
                                    <template
                                        v-if="isUsingCpa"
                                        v-slot:column.conversions="conversions"
                                    >
                                        <Number :value="conversions.value" />
                                    </template>
                                    <template
                                        v-else
                                        v-slot:column.conversionsValue="conversionsValue"
                                    >
                                        <Money :value="conversionsValue.value" />
                                    </template>
                                </oTable>
                            </div>
                        </template>
                    </Popout>
                </template>
                <!-- Buttons -->
                <template v-slot:buttons>
                    <oButton
                        v-if="currentState === 'open-campaign'"
                        @clicked="handleRsaWriterClose"
                        color="white"
                    >
                        Close
                    </oButton>
                    <oButton v-else @clicked="handleBackButton" color="white">Back</oButton>
                    <Spacer width="0.625rem" />
                    <oButton
                        v-if="currentState !== 'preview-rsa'"
                        @clicked="openPreview"
                        :disabled="
                            selectorLoading ||
                            currentState === 'open-rsa' ||
                            currentState === 'open-ad-group' ||
                            currentState === 'open-campaign' ||
                            !!noChangesInDraft
                        "
                    >
                        Preview RSA
                        <template v-slot:icon><RightArrowIcon hex="#fff" /></template>
                    </oButton>
                    <Tooltip
                        :maxWidth="325"
                        :content="
                            maxRsasAlreadyExist && isPreviewingNewRsa
                                ? `Google Ads has a limit of 3 enabled RSAs per ad group. To create an RSA, pause another RSA in this ad group.`
                                : ``
                        "
                    >
                        <oButton
                            v-if="currentState === 'preview-rsa'"
                            @clicked="publishRsa"
                            color="green"
                            :disabled="maxRsasAlreadyExist && isPreviewingNewRsa"
                        >
                            Push RSA
                            <template v-slot:icon><RightArrowIcon hex="#fff" /></template>
                        </oButton>
                    </Tooltip>
                </template>
            </RsaFooter>
        </transition>
    </FullScreenOverlay>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

import { useRsaWriter } from '@/composition/toolkit/rsaWriter/useRsaWriter'
import dummySerp from '@/assets/img/serp-standard-width.png'
import useMediaQuery from '@/composition/global/useMediaQuery'
import Money from '@/components/global/oMoney.vue'

import MobileSplashContainer from '@/components/util/MobileSplashContainer.vue'

import {
    // global
    Avatar,
    Text,
    Label,
    oInput,
    oButton,
    RightArrowIcon,
    Spacer,
    Popout,
    oTable,
    Number,
    Percent,
    CircleAccountLevelIcon,
    CircleCampaignIcon,
    CircleAdgroupIcon,
    CircleKeywordIcon,
    Panel,
    Spinner,
    SelectArrowIcon,
    MoreButton,
    // icons
    rsaCopyIcon,
    rsaLeftArrowIcon,
    rsaRightArrowIcon,
    rsaDesktopIcon,
    rsaMobileIcon,
    rsaBinIcon,
    HelpIcon,
    Tooltip,
} from '@opteo/components-next'

import Skeleton from '@/components/util/Skeleton.vue'
import OnboardingTour from '@/components/User/OnboardingTour.vue'
import FullScreenOverlay from '@/components/util/FullScreenOverlay.vue'

import DraftRowDeleteButton from '@/components/rsaWriter/DraftRowDeleteButton.vue'
import RsaLoaderAnimation from '@/components/rsaWriter/RsaLoaderAnimation.vue'
import RsaAssetCollection from '@/components/rsaWriter/RsaAssetCollection.vue'
import RsaSidebar from '@/components/rsaWriter/RsaSidebar.vue'
import RsaAdStrength from '@/components/rsaWriter/RsaAdStrength.vue'
import RsaExpandedPreview from '@/components/rsaWriter/RsaExpandedPreview.vue'
import RsaFooter from '@/components/rsaWriter/RsaFooter.vue'
import RsaButton from '@/components/rsaWriter/RsaButton.vue'
import RsaRecommendedSearchTerms from '@/components/rsaWriter/RsaRecommendedSearchTerms.vue'
import RsaSerpPreview from '@/components/rsaWriter/RsaSerpPreview.vue'
import RsaStatistics from '@/components/rsaWriter/RsaStatistics.vue'

import ScorecardDonut from '@/components/scorecard/ScorecardDonut.vue'

import GroupRow from '@/components/toolkit/shared-components/GroupRow.vue'
import ToolkitContainer from '@/components/toolkit/shared-components/ToolkitContainer.vue'

// rsaWriter Components
import GoogleSerpContainer from '@/components/rsaWriter/GoogleSerpContainer.vue'
import RsaCompetitorAds from '@/components/rsaWriter/RsaCompetitorAds.vue'
import Rsa from '@/components/rsaWriter/Rsa.vue'
import WritesonicLogo from '@/components/rsaWriter/WritesonicLogo.vue'

import ProgressBar from '@/components/global/ProgressBar.vue'

export default defineComponent({
    name: 'rsaWriter',
    components: {
        // global
        Text,
        Label,
        oInput,
        oButton,
        RightArrowIcon,
        Spacer,
        Popout,
        oTable,
        Number,
        Percent,
        CircleAccountLevelIcon,
        CircleCampaignIcon,
        CircleAdgroupIcon,
        CircleKeywordIcon,
        ScorecardDonut,
        Panel,
        Spinner,
        SelectArrowIcon,
        MoreButton,
        // rsa
        GroupRow,
        ToolkitContainer,
        RsaFooter,
        RsaButton,
        Rsa,
        RsaAdStrength,
        RsaSidebar,
        GoogleSerpContainer,
        RsaCompetitorAds,
        RsaAssetCollection,
        RsaRecommendedSearchTerms,
        RsaSerpPreview,
        RsaExpandedPreview,
        RsaStatistics,
        // icons
        rsaCopyIcon,
        rsaLeftArrowIcon,
        rsaRightArrowIcon,
        rsaDesktopIcon,
        rsaMobileIcon,
        rsaBinIcon,
        WritesonicLogo,
        HelpIcon,
        // local
        Avatar,
        OnboardingTour,
        Skeleton,
        FullScreenOverlay,
        DraftRowDeleteButton,
        RsaLoaderAnimation,
        Tooltip,
        ProgressBar,
        MobileSplashContainer,
        Money,
    },
    setup() {
        const panelOpen = ref(false)
        const showAllCurrentRsas = ref(false)
        const { below1280 } = useMediaQuery()

        return {
            panelOpen,
            showAllCurrentRsas,
            below1280,
            dummySerp,
            ...useRsaWriter(),
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

section.open-campaign,
section.open-ad-group,
section.open-rsa {
    max-width: 71rem;
    margin: 3rem auto 9.25rem auto;
}
section.rsa-writer {
    display: grid;
    grid-template-columns: 22rem 1fr;
    max-width: 100%;
    margin: 0;
    margin-left: 1.5rem;
    margin-top: 1.5rem;
}
section.rsa-writer .rsa-writer-sidebar {
    @include sticky;
    align-self: start;
    top: 1.5rem;
    max-height: calc(100vh - 5rem - 1.5rem - 3rem);
    @include container;
    @include br-20;
    background: $opteo-white;
    overflow: hidden;
    width: 100%;
}
section.rsa-writer .rsa-writer-main-column {
    max-width: 55rem;
    width: 100%;
    margin: 1.5rem auto 9.5rem auto;
}
section.preview-rsa {
    max-width: 55rem;
    margin: 3rem auto 9.25rem auto;
}

.rsa-status {
    width: 7rem;
}
.sort-groups-by {
    min-width: 5.25rem;
    max-width: 6.75rem;
}

.draft-mock-input {
    height: 2.75rem;
    @include container;
    @include br-8;
    @include flex;
    @include items-center;
    padding: 0 0.875rem;
    gap: 1.25rem;
    color: rgba(0, 0, 0, 0.24);
    cursor: default;
    user-select: none;
}

.keywords-popout {
    @include pa-32;
}
.keywords-popout-cell {
    @include flex;
    @include items-center;
    overflow: hidden;
}
.keywords-popout-cell::after {
    content: '';
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 62.5%);
    @include absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 48px;
}

.ai-generated-assets-popout,
.campaign-library-popout {
    @include pa-24;
    @include flex;
    @include items-center;
}
.ai-generated-assets-popout {
    max-width: 24rem;
}
.campaign-library-popout {
    max-width: 30.625rem;
}

.open-rsa-container {
    @include grid;
    gap: 2rem;
}
.rsa-writer-inspiration {
    @include flex;
    gap: 2rem;
    flex-direction: column;
}
.rsa-statistics-panel {
    @include grid;
    gap: 2rem;
}

.no-rsas-empty-state {
    @include container;
    @include br-20;
    padding: 5.5rem 0;
}
.no-rsas-empty-state .content {
    @include flex;
    @include items-center;
    flex-direction: column;
    max-width: 18.75rem;
    margin: 0 auto;
}
.no-campaigns-empty-state {
    height: calc(100vh - 6.5rem);
    @include flex;
    @include items-center;
}
.no-campaigns-empty-state .content {
    @include flex;
    @include items-center;
    flex-direction: column;
    max-width: 22.5rem;
    margin: 0 auto;
}
.no-search-terms-empty-state {
    padding: 4rem 0;
    @include flex;
    @include items-center;
}
.no-search-terms-empty-state .content {
    @include flex;
    @include items-center;
    flex-direction: column;
    max-width: 22.5rem;
    margin: 0 auto;
}
.no-keywords-empty-state {
    @include container;
    @include flex;
    @include items-center;
    width: 46rem;
    height: 24.5625rem;
}
.no-keywords-empty-state .content {
    @include flex;
    @include items-center;
    flex-direction: column;
    max-width: 22.5rem;
    margin: 0 auto;
}
.no-statistics-empty-state {
    height: 40rem;
    @include flex;
    @include items-center;
}
.no-statistics-empty-state .content {
    @include flex;
    @include items-center;
    flex-direction: column;
    max-width: 21.5rem;
    margin: 0 auto;
}

.copy-onboarding {
    @include container;
    @include br-16;
    @include flex;
    @include items-center;
    @include justify-between;
    @include ph-24;
    padding: 1.125rem 1.5rem;
}
.copy-onboarding .left {
    @include flex;
    @include items-center;
    gap: 1rem;
}

// Transitions

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.16s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.page-fade-enter-active,
.page-fade-leave-active {
    transition: opacity 0.48s ease;
}

.page-fade-enter-from,
.page-fade-leave-to {
    opacity: 0;
}

.section-next-enter-active,
.section-next-leave-active {
    transition: all 0s ease 0s;
}

.section-next-enter-from {
    opacity: 0;
    transform: translateX(3rem);
}
.section-next-leave-to {
    opacity: 0;
    transform: translateX(-3rem);
}

.section-prev-enter-active,
.section-prev-leave-active {
    transition: all 0s ease 0s;
}

.section-prev-enter-from {
    opacity: 0;
    transform: translateX(-3rem);
}
.section-prev-leave-to {
    opacity: 0;
    transform: translateX(3rem);
}

// Loader
.loader {
    @include block;
}

.loader .o-input {
    @include block;
}

.loader-container {
    max-width: 42.5rem;
    // halfScreen - halfFooterPlusMargin - halfElement
    margin: calc(50vh - 3.25rem - 14.5rem) auto 0 auto;
}

.loader .progress-bar-container {
    @include ph-64;
    @include mt-64;
}

.no-campaigns-empty-state-container {
    @include flex;
    @include items-center;
    @include justify-center;
    @include tc;
    flex-direction: column;
    height: 30rem;
}

.more-button {
    position: fixed;
    top: 5.375rem;
    right: 1.5rem;
}

// Responsive

// Below 1280px
@media (max-width: 1280px) {
    .hidden-string {
        display: none;
    }
    section.open-campaign,
    section.open-ad-group,
    section.open-rsa {
        max-width: 68rem;
    }
    .loader .progress-bar-container {
        @include mt-48;
    }
    .more-button {
        position: fixed;
        top: 4.75rem;
        right: 1rem;
    }
    section.rsa-writer {
        display: grid;
        grid-template-columns: 20rem 1fr;
        max-width: 100%;
        margin: 0;
        margin-left: 1.5rem;
        margin-top: 1.5rem;
    }
    section.rsa-writer .rsa-writer-main-column {
        max-width: 48.5rem;
        width: 100%;
        margin: 1.5rem auto 9.5rem auto;
    }
    // section.preview-rsa {
    //     max-width: 55rem;
    //     margin: 3rem auto 9.25rem auto;
    // }
}
</style>
